.testimonial-wrapper {
    padding: 15px;
}
.single-testimonial {
    color: #fff;
    padding: 15px;
    background: #222;
    border-radius: 8px;
}
.single-testimonial img {
    border-radius: 50%;
    height: 80px;
    width: 80px;
    object-fit: cover;
    margin-right: 15px;
}
.single-testimonial span {
    font-size: 20px;
}

.testimonial-text {
    min-height: 100px;
}