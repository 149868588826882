@import url('https://fonts.googleapis.com/css2?family=Hind+Siliguri:wght@300;400;500;600;700&display=swap');

html,
body {
  background-color: #000;
  background-image: url('./assets/bg.png');
  height: 100%;
  font-family: "Hind Siliguri", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.hind-siliguri-light {
  font-family: "Hind Siliguri", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.hind-siliguri-regular {
  font-family: "Hind Siliguri", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.hind-siliguri-medium {
  font-family: "Hind Siliguri", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.hind-siliguri-semibold {
  font-family: "Hind Siliguri", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.hind-siliguri-bold {
  font-family: "Hind Siliguri", sans-serif;
  font-weight: 700;
  font-style: normal;
}


section {
  padding-top: 5%;
  padding-bottom: 5%;
}

a {
  color: #fff;
  text-decoration: none;
}

.m-t-n-15 {
  margin-top: -15px;
}

.mt-page-header {
  margin-top: 5%;
}

.display-block-none {
  display: none;
}

@media (max-width: 767.98px) {
  .mt-page-header {
    margin-top: 40%;
  }
}

@media (max-width: 575.98px) {
  .display-block-none {
    display: block;
  }
}