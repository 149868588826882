Header {
    position: fixed;
    z-index: 999;
    padding: 15px;
    transition-duration: 0.5s;
    -webkit-transition-duration: 0.5s;
    -moz-transition-duration: 0.5s;
    -o-transition-duration: 0.5s;
}
.logo-image {
    width: 80px;
}
.header-info {
    padding-top: 7px;
    padding-bottom: 7px;
}

.sticky {
    background: #000;
    box-shadow: 0px 0px 60px -15px #edc45a;
    padding-top: 5px;
    padding-bottom: 5px;
    z-index: 9999;
    transition-duration: 0.5s;
    -webkit-transition-duration: 0.5s;
    -moz-transition-duration: 0.5s;
    -o-transition-duration: 0.5s;
}
.sticky .logo-image {
    width: 50px;
    transition-duration: 0.5s;
    -webkit-transition-duration: 0.5s;
    -moz-transition-duration: 0.5s;
    -o-transition-duration: 0.5s;
}