.collabotate-box {
    background-color: #ffecb9;
    padding: 3%;
}

.collaborate-button {
    background-color: #1f775e;
    color: #ffecb9;
    padding: 15px;
    display: inline-block;
    text-decoration: none;
    border-radius: 8px;
    margin-top: 15px;
}

.contact-button{
    background-color: #1f775e;
    color: #ffecb9;
    padding: 10px 40px;
    display: inline-block;
    text-decoration: none;
    border-radius: 8px;
    border: none;
}

.contact-box {
    background-color: #fff;
    padding: 3%;
}

.contact-box .form-control:focus {
    border: 1px solid #000;
    box-shadow: none;
}
.contact-box textarea {
    height: 120px !important;
}