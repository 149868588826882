.footer {
    padding: 15px;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
}

.footer-links {
    margin: 0;
    padding: 0;
}

.footer-links li {
    display: inline-block;
    color: #fff;
    padding: 15px;
}

.footer-links li a {
    color: #fff;
    text-decoration: none;
}

.whatsapp-icon {
    position: fixed;
    bottom: 3%;
    right: 3%;
    text-align: right;
}

@media (max-width: 575.98px) {
    .footer-links li {
        padding: 6px;
    }
  }