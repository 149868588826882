.content-section {
    color: #fff;
    font-size: 20px;
    min-height: 60vh;
}

.content-section ul {
    padding-left: 20px;
}

.content-section ul li {
    list-style-type: disclosure-closed;
}

.read-more {
    text-decoration: underline;
    cursor: pointer;
}